<template>
  <v-card>
    <v-card-text :class="`d-flex ${imLeft ? 'left' : 'right'} ${dark ? 'white' : 'black'}--text pa-1`">
      <div class="d-flex stack justify-center grow" style="width: 50%">
        <!-- PLAYERS -->
        <template v-if="usePlayers">
          <div
            v-for="player in (lineups && lineups.length > 0 ? lineupPlayers : team.players)"
            :key="player.id"
            :class="`${xs ? '' : 'title '}d-flex align-center ${imLeft ? 'left' : 'right'}${noAvatar ? ' pb-2' : ''}`"
          >
            <v-avatar
              v-if="!noAvatar"
              :size="xs ? 40 : 60"
            >
              <img :src="player.profilePic" :alt="player.name">
            </v-avatar>
            <div class="px-2 text-truncate">
              <div style="line-height:1;" :class="{ 'font-weight-bold': team.logo}">{{player.name}}</div>
              <div class="caption" v-if="displayOnly">
                <v-btn color="color3" x-small text :to="{name: 'player-profile', params: {playerProfileId: player.ppId}}">view profile</v-btn>
              </div>
            </div>
          </div>
        </template>
        <!-- TEAM NAMES -->
        <template v-else>
          <div :class="`title d-flex align-center ${imLeft ? 'left' : 'right'}`">
            <div>
              <v-img
                v-if="team.logox"
                :src="team.logo"
                height="48"
                max-width="64"
                contain
              ></v-img>
              {{team.name}} {{dualDisplay}}
            </div>
          </div>
          <div v-if="match.isDual" :class="`title d-flex align-center ${imLeft ? 'left' : 'right'}`">
            <div class="grey--text">Pair {{ match.dualMatchN }}</div>
          </div>
        </template>
      </div>
      <div v-if="game" class="align-center" :class="{ 'd-flex': orientation === 'landscape', 'justify-end': imLeft, 'justify-start': !imLeft }">
        <div class="shrink">
          <v-col :class="`pa-0 shrink align-center d-flex text-h2 justify-${imLeft ? 'start' : 'end'}`">
            <!-- LEFT DIGIT -->
            <v-col class="pa-0" :order="imLeft ? 1 : 2">
              <v-card
                width="80"
                height="100"
                :color="`${ home ? 'red' : 'blue' } white--text`"
                class="align-center d-flex justify-center score"
                v-touch="{
                  left: () => swipe('Left'),
                  right: () => swipe('Right'),
                  up: () => plus(10),
                  down: () => subtract(10)
                }"
              >
                <div class="text-center">{{score | tens}}</div>
              </v-card>
            </v-col>
            <!-- LEFT DIGIT -->
            <v-col class="pa-0" :order="imLeft ? 2 : 3">
              <v-card
                width="80"
                height="100"
                :color="`${ home ? 'red' : 'blue' } white--text`"
                class="align-center d-flex justify-center score"
                v-touch="{
                  left: () => swipe('Left'),
                  right: () => swipe('Right'),
                  up: () => plus(1),
                  down: () => subtract(1)
                }"
              >
                <div class="text-center">{{score | singles}}</div>
              </v-card>
            </v-col>
            <!-- BUTTONS -->
            <v-col class="pa-0" :order="imLeft ? 3 : 1" v-if="orientation === 'landscape'">
              <v-card
                width="80"
                height="100"
                flat
                class="d-flex flex-column text-center justify-space-around score"
              >
                <v-card-text class="pa-0">
                  <v-btn color="success" @click.stop="plus(1)">
                    <v-icon>fas fa-plus</v-icon>
                  </v-btn>
                </v-card-text>
                <v-card-text class="pa-0">
                  <v-btn color="error" @click.stop="subtract(1)">
                    <v-icon>fas fa-minus</v-icon>
                  </v-btn>
                </v-card-text>
              </v-card>
            </v-col>
          </v-col>
        </div>
        <div v-if="orientation !== 'landscape'" class="text-center pt-2 score">
          <v-card
            flat
            class="d-flex text-center justify-space-around score"
          >
            <v-card-text class="pa-0">
              <v-btn color="success" @click.stop="plus(1)">
                <v-icon>fas fa-plus</v-icon>
              </v-btn>
            </v-card-text>
            <v-card-text class="pa-0">
              <v-btn color="error" @click.stop="subtract(1)">
                <v-icon>fas fa-minus</v-icon>
              </v-btn>
            </v-card-text>
          </v-card>
        </div>
      </div>
      <div class="water d-flex justify-center align-center" :class="{'offset-logo': $vuetify.breakpoint.xs}">
        <v-img
          v-if="team.logo"
          :src="team.logo"
          height="48"
          max-width="64"
          contain
        ></v-img>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex'
import { formatArraySorted } from '@/Filters'
import flatten from '@/helpers/ArrayFlatten'
import { firstBy } from 'thenby'
import { usePlayerNames } from '@/classes/HelperFunctions'

export default {
  props: ['match', 'home', 'switchSides', 'gameI', 'dark', 'orientation', 'division', 'displayOnly', 'forceXs'],
  data () {
    return {
      swipeDirection: 'none'
    }
  },
  computed: {
    ...mapGetters(['getTeam', 'tournament']),
    xs () {
      return this.$vuetify.breakpoint.xsOnly || this.forceXs
    },
    usePlayers () {
      return (this.lineupPlayers && this.lineupPlayers.length > 0) || usePlayerNames(this.team, this.division, this.match.isDual)
    },
    noAvatar () {
      return this.division.noAvatar
    },
    games () {
      return this.match.games.length
    },
    game () {
      return this.match.games[this.gameI]
    },
    score: {
      get () {
        return this.home ? this.game.home : this.game.away
      },
      set (val) {
        if (this.home) {
          this.game.home = val
        } else {
          this.game.away = val
        }
      }
    },
    homeSide () {
      return this.switchSides ? 'right' : 'left'
    },
    imLeft () {
      return this.home ? !this.switchSides : this.switchSides
    },
    team () {
      return this.home ? this.homeTeam : this.awayTeam
    },
    homeTeam () {
      if (this.match.homeTeam) return this.getTeam(this.match.homeTeam.teamId)
      if (this.match.homeTeamIds) {
        const teams = this.match.homeTeamIds.map(m => this.getTeam(m))
        return {
          name: formatArraySorted(teams.map(m => m.name)),
          players: flatten(teams.map(m => m.players))
        }
      }
      return null
    },
    awayTeam () {
      if (this.match.awayTeam) return this.getTeam(this.match.awayTeam.teamId)
      if (this.match.awayTeamIds) {
        const teams = this.match.awayTeamIds.map(m => this.getTeam(m))
        return {
          name: formatArraySorted(teams.map(m => m.name)),
          players: flatten(teams.map(m => m.players))
        }
      }
      return null
    },
    pairN () {
      if (!this.match.isDual) return 1
      return (this.match.dualMatchN || 0) + this.division.dualAdj
    },
    dualDisplay () {
      if (!this.match.isDual) return ''
      return `Pair ${this.pairN}`
    },
    lineups () {
      return this.match && this.match.lineups && this.match.lineups.filter(f => f.teamId === this.team.id)
    },
    myLineups () {
      return this.lineups && flatten(this.lineups.map(m => m.positions.filter(f => f.n === this.pairN && f.type === 'dual')))
    },
    lineupPlayers () {
      if (!this.myLineups) return null
      const l = flatten(this.myLineups.map(m => m.players))
      const p = l && this.teamPlayers && this.teamPlayers.filter(f => l.includes(f.id))
      p && p.sort(firstBy('lastName').thenBy('firstName'))
      return p
    },
    teamPlayers () {
      return this.team.players
    }
  },
  methods: {
    plus (n) {
      this.score = this.score + n
    },
    subtract (n) {
      this.score = this.score - n
    },
    swipe (direction) {
      this.swipeDirection = direction
    }
  }
}
</script>

<style scoped>
.left {
  flex-direction: row;
}
.right {
  flex-direction: row-reverse;
}
.stack {
  flex-direction: column;
}
.score {
  z-index: 9999;
}
.water {
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0.2;
}

.offset-logo {
  width: calc(100% - 70px) !important
}
</style>
