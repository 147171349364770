<template>
  <v-container fluid :class="{'pa-0': $vuetify.breakpoint.smAndDown}">
    <v-row dense>
      <v-col cols="12" class="pa-0">
        <v-card>
          <v-toolbar color="color1 color1Text--text" :dark="color1IsDark">
            <v-toolbar-title>Live Streams</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-text-field
              :slot="$vuetify.breakpoint.smAndDown ? 'extension' : null"
              color="color3"
              append-icon="fas fa-search"
              label="Search"
              hide-details
              v-model="search"
              solo-inverted
              clearable
              :class="{'mb-3': $vuetify.breakpoint.smAndDown}"
            ></v-text-field>
          </v-toolbar>
          <v-card-text class="pa-3">
            <v-data-iterator
              :items="streams"
              :options.sync="dataOptions"
              :search="search"
              hide-default-footer
            >
              <template v-slot:default="props">
                <v-row dense>
                  <v-col
                    v-for="item in props.items"
                    :key="item.id"
                    cols="12" sm="6" md="4" xl="3"
                  >
                    <stream-card :item="item" :key="item.id"></stream-card>
                  </v-col>
                </v-row>
              </template>
            </v-data-iterator>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
import StreamCard from '@/components/LiveStream/StreamCard.vue'
import { api } from '@/classes/_URL'

export default {
  data () {
    return {
      search: null,
      dataOptions: {
        itemsPerPage: -1,
        sortBy: ['complete', 'unix'],
        sortDesc: [false, true]
      }
    }
  },
  computed: {
    ...mapGetters(['tournament', 'color1IsDark', 'view']),
    streams () {
      return this.tournament.mappedStreams.filter(f => !!f.match && !f.deleted && f.match.status)
    },
    matches () {
      return this.tournament.matches
    }
  },
  methods: {
    getProps (stream) {
      const base = [
        stream.division.name,
        stream.round.name
      ]
      if (stream.pool) {
        base.push(`Pool ${stream.pool.name}`)
        base.push(`Match ${stream.match.displayNumber || stream.match.number}`)
      } else {
        if (stream.match.isWinners) {
          stream.bracket.losers.length && base.push('Winners Bracket')
        } else {
          base.push(stream.bracket.losersName)
        }
        base.push(stream.bracket.getMatchTitle(stream.match))
      }

      return base
    },
    watch (item) {
      this.loading = true
      this.$http.get(`${api}/livestream/watch/${item.id}`)
        .then(r => {
          window.open(r.data, '_blank')
        })
        .finally(() => {
          this.loading = false
        })
    }
  },
  watch: {
    matches: function (val) {
      this.tournament.mapAllStream()
    }
  },
  components: {
    StreamCard
  },
  mounted () {
    this.tournament.mapAllStream()
  }
}
</script>
