<template>
  <v-card color="grey lighten-3" class="fill-height">
    <v-card-title class="pb-0">
      {{item.matchType}} Match
      <v-spacer></v-spacer>
      <v-btn
        color="white error--text"
        v-if="!item.match.complete"
        @click.stop="watch(item)"
      >
        <v-icon class="mr-2">fas fa-signal-stream</v-icon> Now Streaming
      </v-btn>
      <v-btn
        v-else
        color="color3"
        text small
        @click.stop="watch(item)"
      >
        watch replay
        <v-icon class="ml-2">fas fa-tv</v-icon>
      </v-btn>
    </v-card-title>
    <v-card-text>
      <div>{{getProps(item) | pipeArray}}</div>
      <div class="caption">Started: {{item.started | unixToLocalDt}}</div>
      <div style="position:relative;">
        <v-col cols="12" class="py-0 mb-6">
          <team-card
            :match="item.match"
            :division="item.division"
            :home="true"
            :dark="false"
            :displayOnly="true"
            :forceXs="true"
          ></team-card>
        </v-col>
        <v-col cols="12" class="py-0">
          <team-card
            :match="item.match"
            :division="item.division"
            :home="false"
            :dark="false"
            :displayOnly="true"
            :forceXs="true"
          ></team-card>
        </v-col>
        <v-fab-transition>
          <div v-if="!confirmDelete" class="pa-0 text-center vs d-flex justify-center align-center">
            <v-avatar
              color="color1 color1Text--text"
              :size="60"
              class="elevation-4 title"
              style="z-index: 4"
            >
              vs
            </v-avatar>
          </div>
        </v-fab-transition>
      </div>
    </v-card-text>
    <v-card-actions :class="`justify-${view.admin ? 'space-between': 'end'}`">
      <v-btn
        color="error"
        small text icon
        @click.stop="confirmDelete = true"
        v-if="view.admin"
      >
        <v-icon>fas fa-trash</v-icon>
      </v-btn>
      <v-btn color="color3" text :to="{name : item.pool ? 'pool-sheet' : 'bracket-home', params: { tournamentId: tournament.id, divisionId: item.division.id, dayId: item.round.id, poolId: item.poolId, bracketId: item.bracketId }}">
        Jump to the {{item.pool ? 'Pool Sheet' : 'Bracket'}} <v-icon class="ml-3">fas fa-caret-right</v-icon>
      </v-btn>
    </v-card-actions>
    <v-expand-transition>
      <v-container
        v-if="confirmDelete"
        class="d-flex transition-fast-in-fast-out title v-card--reveal"
        style="height: 100%;"
      >
        <v-card style="max-width: 300px">
          <v-card-title>Delete this stream?</v-card-title>
          <v-card-actions class="d-flex justify-center">
            <v-btn color="success" :loading="deleteing" @click.stop="deleteStream">Yes</v-btn>
            <v-btn color="error" @click.stop="confirmDelete=false" :disabled="deleteing">No</v-btn>
          </v-card-actions>

        </v-card>
      </v-container>
    </v-expand-transition>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex'
import TeamCard from '@/components/Tournament/LiveScoring/TeamCard.vue'
import { api } from '@/classes/_URL'

export default {
  props: ['item'],
  data () {
    return {
      confirmDelete: false,
      deleteing: false
    }
  },
  computed: {
    ...mapGetters(['tournament', 'color1IsDark', 'view']),
    streams () {
      return this.tournament.mappedStreams.filter(f => !!f.match)
    },
    matches () {
      return this.tournament.matches
    },
    xs () {
      return this.$vuetify.breakpoint.xsOnly
    }
  },
  methods: {
    getProps (stream) {
      const base = [
        stream.division.name,
        stream.round.name
      ]
      if (stream.pool) {
        base.push(`Pool ${stream.pool.name}`)
        base.push(`Match ${stream.match.displayNumber || stream.match.number}`)
      } else {
        if (stream.match.isWinners) {
          stream.bracket.losers.length && base.push('Winners Bracket')
        } else {
          base.push(stream.bracket.losersName)
        }
        base.push(stream.bracket.getMatchTitle(stream.match))
      }

      return base
    },
    watch (item) {
      this.loading = true
      this.$http.get(`${api}/livestream/watch/${item.id}`)
        .then(r => {
          window.open(r.data, '_blank')
        })
        .finally(() => {
          this.loading = false
        })
    },
    deleteStream () {
      this.deleteing = true
      this.$VBL.tournament.streams.delete(this.tournament.id, this.item.id)
        .then(r => {
          this.item.deleted = true
        })
        .catch(e => console.log(e.response))
        .finally(() => {
          this.deleteing = false
        })
    }
  },
  components: {
    TeamCard
  }
}
</script>
<style scoped>
.vs {
  position: absolute;
  top: calc(50% - 30px);
  left: calc(50% - 30px);
  width: 60px;
  height: 60px;
}
</style>
